import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import GQLCodeSnippet, { GQLOpen, GQLClose, GQLLine, GQLTab, GQLName, GQLKeyword, GQLAttribute, GQLOperator, GQLComment, GQLLink } from "../../components/GQLCodeSnippet/GQLCodeSnippet";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The PatchLevel scalar type is a security patch level represented as UTF-8 character sequences.
Accepted format is: `}<inlineCode parentName="p">{`concat(YEAR,MONTH,DAY,HOUR,MINUTE,SECOND)`}</inlineCode>{`.
i.e: a patch level generated on `}<inlineCode parentName="p">{`2019-07-22`}</inlineCode>{` at `}<inlineCode parentName="p">{`10:48:31`}</inlineCode>{` is represented as: `}<inlineCode parentName="p">{`20190722104831`}</inlineCode>{`.`}</p>
    <GQLCodeSnippet mdxType="GQLCodeSnippet">
      <GQLLine mdxType="GQLLine"><GQLKeyword mdxType="GQLKeyword">scalar</GQLKeyword> PatchLevel</GQLLine>
    </GQLCodeSnippet>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      